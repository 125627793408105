<template>
  <div class="iframe-wrapper">
    <iframe
      id="portalBuilderiFrame"
      :src="fileLink"
      frameborder="0"
      height="100%"
      width="100%"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "PortalIframe",

  computed: {
    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (
        origin === "https://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        return `${origin}/EZPORTAL`;
      } else {
        return `${origin}/PORTAL`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.iframe-wrapper {
  height: calc(100vh - 129px);
  padding-top: 2px;
}
</style>
