import { render, staticRenderFns } from "./ActionBar.vue?vue&type=template&id=6105805f&scoped=true&"
import script from "./ActionBar.vue?vue&type=script&lang=js&"
export * from "./ActionBar.vue?vue&type=script&lang=js&"
import style0 from "./ActionBar.vue?vue&type=style&index=0&id=6105805f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6105805f",
  null
  
)

export default component.exports
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpace});
