<template>
  <FormSection
    :icon="
      isActive ? 'eva-question-mark-circle' : 'eva-question-mark-circle-outline'
    "
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>How it Works</template>
    <template #description>
      Add sections your portal and provide detailed description for your
      reference
    </template>

    <div class="q-pa-md" style="border: 1px solid var(--divider-color)">
      <div id="global-search" class="q-mb-md">
        <BaseIcon name="eva-search" class="q-ml-xs" />

        <div class="label">
          <input
            ref="searchQuery"
            v-model="search"
            type="text"
            class="q-px-xs"
            placeholder="Search ..."
          />
        </div>
      </div>

      <div
        class="add-section row justify-center items-center q-mb-md"
        @click="addSection"
      >
        <BaseIcon name="eva-plus" class="q-mr-sm" />
        <div class="">Add Section</div>
      </div>
      <Draggable v-model="settings">
        <template v-for="(setting, index) in _settings">
          <div :key="setting.id" class="help-section row items-center q-mt-sm">
            <div class="col">{{ setting.title }}</div>
            <div class="col-1 edit">
              <BaseIcon
                name="eva-edit-outline"
                size="22px"
                class="q-mr-md cursor-pointer"
                @click="editSection(setting)"
              />
            </div>
            <div class="col-1 delete">
              <BaseIcon
                name="eva-trash-outline"
                size="22px"
                class="cursor-pointer"
                @click="deleteSection(index)"
              />
            </div>
          </div>
        </template>
      </Draggable>
    </div>

    <Sheet
      :value="showAddSectionModal"
      width="570px"
      no-padding
      has-footer
      @input="closeSection"
    >
      <!-- title -->

      <template #title>
        {{ editModal ? "Edit" : "Add" }} Section Settings
      </template>

      <!-- ... -->

      <!-- form -->

      <template #default>
        <div id="settings" class="row">
          <!-- ... -->

          <div class="content field">
            <ValidationObserver ref="form">
              <ValidationProvider
                v-slot="{ errors }"
                name="title"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="title"
                  label="title"
                  is-mandatory
                  :error="errors[0]"
                  class="q-mb-md"
                />
              </ValidationProvider>

              <!-- description -->

              <TextAreaField
                v-model="description"
                label="description"
                class="q-mb-lg"
              />

              <!-- ... -->

              <ValidationProvider
                v-slot="{ errors }"
                name="image"
                :rules="{ required: true }"
              >
                <ImageField
                  v-model="image"
                  label="image"
                  :is-mandatory="true"
                  :error="errors[0]"
                  class="q-mb-md"
                />
              </ValidationProvider>

              <Tabs v-model="tab" :tabs="tabs" class="tabs" />

              <template v-if="tab === 'Link'">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="link"
                  :rules="{ url: true }"
                >
                  <TextField
                    v-model="linkTo.link"
                    label="link"
                    :error="errors[0]"
                    class="q-my-sm"
                  />
                </ValidationProvider>
              </template>

              <template v-if="tab === 'File'">
                <Dropzone
                  v-if="!filesToUpload.length"
                  :show-workspace-repository="false"
                  :workspace-id="0"
                  :repository-id="0"
                  :has-multiple="false"
                  @upload="queueFilesForUpload"
                />

                <UploadingFileList
                  v-if="filesToUpload.length"
                  :files="filesToUpload"
                  :show-progress="showProgress"
                  :show-delete-btn="showDeleteBtn"
                  @delete="deleteUploadFile"
                />
              </template>

              <SingleChoiceField
                v-model="linkTo.openWindow"
                label="open in new window"
                :options="options"
                :options-per-line="2"
                class="q-mb-md"
              />
            </ValidationObserver>
          </div>

          <!-- ... -->
        </div>
      </template>

      <!-- ... -->

      <!-- footer -->

      <template #footer>
        <BaseButton
          label="cancel"
          is-flat
          color="secondary"
          class="q-mr-sm"
          @click="closeSection"
        />

        <BaseButton label="save" color="secondary" @click="saveSection" />
      </template>

      <!-- ... -->
    </Sheet>
  </FormSection>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import FormSection from "@/components/common/item-builder/FormSection.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import ImageField from "@/components/common/form/image-field/ImageField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import Tabs from "@/components/common/tabs/Tabs2.vue";
import Dropzone from "@/views/upload-and-index/views/uploader/components/Dropzone";
import UploadingFileList from "@/views/upload-and-index/views/uploader/components/UploadingFileList";
import Draggable from "@/components/common/Draggable.vue";

export default {
  name: "HowItWorksSettings",

  components: {
    FormSection,
    ValidationObserver,
    ValidationProvider,
    ImageField,
    TextField,
    SingleChoiceField,
    TextAreaField,
    Dropzone,
    Sheet,
    Tabs,
    UploadingFileList,
    Draggable,
  },

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },

    sectionSettings: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      search: "",
      showAddSectionModal: false,
      title: "",
      description: "",
      image: "",
      linkTo: {
        link: "",
        openWindow: false,
        attachment: "",
      },
      settings: [],
      tab: "Link",
      tabs: [
        {
          id: this.$nano.id(),
          label: "Link",
          value: "Link",
        },
        {
          id: this.$nano.id(),
          label: "File",
          value: "File",
        },
      ],
      options: [
        {
          id: this.$nano.id(),
          label: "Yes",
          value: true,
        },
        {
          id: this.$nano.id(),
          label: "No",
          value: false,
        },
      ],
      filesToUpload: [],
      showProgress: false,
      showDeleteBtn: true,
      editModal: false,
      nanoId: "",
    };
  },

  computed: {
    _settings() {
      if (this.search) {
        let data = this.settings.filter((item) =>
          item.title.toLowerCase().includes(this.search.toLowerCase())
        );
        return data;
      }
      return this.settings;
    },
  },

  watch: {
    sectionSettings: {
      immediate: true,
      handler() {
        if (this.sectionSettings.length) {
          this.settings = this.sectionSettings;
        }
      },
    },
  },

  methods: {
    addSection() {
      this.showAddSectionModal = true;
      this.title = "";
      this.description = "";
      this.image = "";
      this.linkTo = {
        link: "",
        openWindow: false,
      };
      this.tab = "Link";
      this.editModal = false;
    },

    async saveSection() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      if (this.editModal) {
        let index = this.settings.findIndex((item) => {
          return item.id === this.nanoId;
        });
        if (index >= 0) {
          ///
          this.settings[index].title = this.title;
          this.settings[index].description = this.description;
          this.settings[index].imageURL = this.image;
          this.settings[index].linkTo = this.linkTo;
          this.settings[index].file = this.filesToUpload.length
            ? this.filesToUpload[0]
            : "";
        }
      } else {
        this.settings.push({
          id: this.$nano.id(),
          title: this.title,
          description: this.description,
          imageURL: this.image,
          linkTo: this.linkTo,
          file: this.filesToUpload.length ? this.filesToUpload[0] : "",
        });
      }

      this.$emit("update:sectionSettings", this.settings);

      this.closeSection();
    },

    closeSection() {
      this.showAddSectionModal = false;
      this.title = "";
      this.description = "";
      this.image = "";
      this.linkTo = {
        link: "",
        openWindow: false,
      };
      this.filesToUpload = [];
      this.nanoId = "";
    },

    queueFilesForUpload(file) {
      console.log(file);
      this.filesToUpload.push(...file);
      this.linkTo.attachment = file[0].file;
    },

    deleteUploadFile(index) {
      this.filesToUpload.splice(index, 1);
      this.linkTo.attachment = "";
    },

    editSection(section) {
      this.showAddSectionModal = true;
      this.editModal = true;
      this.nanoId = section.id;
      this.title = section.title;
      this.description = section.description;
      this.image = section.imageURL;
      this.linkTo = section.linkTo;
      if (section.file) {
        this.filesToUpload.push(section.file);
      } else {
        this.filesToUpload = [];
      }
    },

    deleteSection(index) {
      this.settings.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
#global-search {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  color: var(--icon-color);
  border: 1px solid var(--divider-color);

  .icon {
    color: var(--icon-color-inverted);
    border-right: 1px solid var(--divider-color);
    margin-right: 5px;
    padding: 7px;

    &.is-selected {
      color: var(--secondary) !important;
    }
  }

  .label {
    @extend .text-sm;
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 8px;
  }

  &:hover {
    cursor: pointer;

    .icon,
    .label {
      color: var(--secondary);
    }
  }

  &.is-focused {
    .icon {
      color: var(--secondary);
    }
  }
}

.add-section {
  border: 3px dashed var(--secondary);
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  line-height: 1.25rem;
  font-weight: 500;
  padding: 10px;
}

.help-section {
  border: 3px solid #d3ecf0;
  border-radius: 8px;
  cursor: all-scroll;
  font-size: 13px;
  line-height: 1.25rem;
  // font-weight: 500;
  padding: 10px;
  background: #d3ecf0;
}

#settings {
  .field {
    margin: 16px 16px 24px 16px;
  }

  .content {
    flex: 1;
    padding-bottom: 36px;
    // border-right: 1px solid var(--divider-color);
  }
}
</style>
<style lang="scss">
#image-field .image-preview .image img {
  width: auto !important;
}

#single-choice-field.singleChoice {
  .options-wrapper {
    border-top: none !important;
    padding-top: 0px !important;
  }
}

#dropzone:not(.is-indexing) {
  height: max(302px, 100vh - 403px) !important;
  margin: 14px 0px !important;
}

#file-list .header {
  padding: 0px !important;
}

#file-list-item {
  padding: 0px !important;
}
</style>
