<template>
  <div id="code-area" @click="$emit('click')">
    <CheckboxField
      is-readonly
      :value="value"
      :label="label"
      :description="description"
    />

    <div v-if="value" style="margin-left: 36px">
      <div class="code-container">
        <BaseScrollbar height="120px" class="q-pa-sm">
          {{ code }}
        </BaseScrollbar>
      </div>

      <BaseButton label="Copy Code" class="q-mt-md" />
    </div>
  </div>
</template>

<script>
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";

export default {
  name: "CodeArea",

  components: {
    CheckboxField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    code: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#code-area {
  .code-container {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    margin-top: 16px;
  }
}
</style>
