<template>
  <div id="portal-builder">
    <PortalHeader
      :portal-settings="portal.settings"
      @settings="showSettings"
      @save="savePortal"
    />

    <PortalIframe />
  </div>
</template>

<script>
import PortalHeader from "./components/PortalHeader.vue";
import PortalIframe from "./components/portal-iframe/PortalIframe.vue";

export default {
  name: "PortalBuilder",

  components: { PortalHeader, PortalIframe },

  props: {
    portalId: {
      type: String,
      default: "",
    },
    portal: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    showSettings() {
      this.$emit("settings");
    },

    savePortal() {
      this.$emit("save");
    },
  },
};
</script>

<style lang="scss" scoped>
#portal-builder {
  min-height: calc(100vh - 68px);
  background-color: var(--body-bg-color);
}
</style>
