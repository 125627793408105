<template>
  <FormSection
    :icon="isActive ? 'mdi-bell-ring' : 'mdi-bell-ring-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Notifications</template>
    <template #description>
      Send Notification To the Logi Portal Url for New Users And Updates
      Exsisting Master Entries
    </template>

    <!-- login notification types -->

    <CheckboxField
      v-model="authentication.notification"
      label="Enable Notification To External User"
      description="Sends Notification to Login Portal Url For new User and Updates Existing Master Entries"
      class="q-pt-md"
      @input="updateNotification"
    />
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";

export default {
  name: "NotificationSettings",

  components: {
    FormSection,
    CheckboxField,
  },

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },

    authentication: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    updateNotification(value) {
      this.authentication.notification = value;
      this.$emit("update:authentication", this.authentication);
    },
  },
};
</script>

<style lang="scss" scoped></style>
