<template>
  <FormSection
    :icon="isActive ? 'eva-options-2' : 'eva-options-2-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>General</template>
    <template #description>
      Name your portal and provide detailed description for your reference
    </template>

    <!-- name -->

    <ValidationProvider
      v-slot="{ errors }"
      name="name"
      :rules="{ required: true }"
    >
      <TextField
        :value="name"
        label="name"
        is-mandatory
        :is-readonly="mode === 'EDIT'"
        :error="errors[0]"
        class="q-mb-lg"
        @input="updateName"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- description -->

    <TextAreaField
      :value="description"
      label="description"
      class="q-mb-lg"
      @input="updateDescription"
    />

    <!-- ... -->

    <!-- workflow -->

    <SelectField
      v-if="false"
      :value="workflow"
      is-mandatory
      label="Workflow"
      :options="workflowList"
      class="q-mb-sm"
      @input="updateWorkflow"
    />
    <WorkflowSettings
      :workflow-list="workflowList"
      :workflow-category-settings="workflowCategorySettings"
      class="q-mb-md"
    />

    <!-- ... -->

    <!--  superuser -->

    <MultiSelectField
      :value="superuser"
      label="Superuser"
      :options="userList"
      class="q-mb-sm"
      @input="updateSuperuser"
    />

    <!-- ... -->
  </FormSection>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FormSection from "@/components/common/item-builder/FormSection.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import WorkflowSettings from "./components/WorkflowSettings.vue";

export default {
  name: "GeneralSettings",

  components: {
    FormSection,
    ValidationProvider,
    TextField,
    TextAreaField,
    SelectField,
    MultiSelectField,
    WorkflowSettings,
  },

  props: {
    portal: {
      type: Object,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    workflow: {
      type: Number,
      default: 0,
    },

    superuser: {
      type: Array,
      default: () => [],
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    workflowList: {
      type: Array,
      default: () => [],
    },

    userList: {
      type: Array,
      default: () => [],
    },

    workflowCategorySettings: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    updateName(name) {
      this.$emit("update:name", name);
    },

    updateDescription(description) {
      this.$emit("update:description", description);
    },

    updateWorkflow(workflow) {
      this.$emit("update:workflow", workflow);
    },

    updateSuperuser(superuser) {
      this.$emit("update:superuser", superuser);
    },
  },
};
</script>

<style lang="scss" scoped></style>
