<template>
  <FormSection
    :icon="isActive ? 'eva-share' : 'eva-share-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Share</template>
    <template #description>
      Easily share and collaborate on your portals through multiple channels,
      including URL, QR code, and email
    </template>

    <!-- portal url -->

    <BaseFeatureLock>
      <TextField
        :value="portalUrl"
        label="portal url"
        is-mandatory
        is-readonly
        class="q-mb-md"
      />

      <BaseButton label="Copy URL" class="q-mb-lg" />
    </BaseFeatureLock>

    <!-- ... -->

    <!-- portal qrCode -->

    <BaseFeatureLock>
      <QRCode class="q-mb-lg" />
    </BaseFeatureLock>

    <!-- ... -->

    <!-- email to -->

    <BaseFeatureLock>
      <EmailTo
        :email-to-internal-users="emailToInternalUsers"
        :email-to-external-users="emailToExternalUsers"
        @updateInternalUsers="updateInternalUsers"
        @updateExternalUsers="updateExternalUsers"
      />
    </BaseFeatureLock>

    <!-- ... -->
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import QRCode from "./components/QRCode.vue";
import EmailTo from "./components/EmailTo.vue";

export default {
  name: "ShareOptions",

  components: {
    FormSection,
    TextField,
    QRCode,
    EmailTo,
  },

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    emailToInternalUsers: {
      type: Array,
      required: true,
    },

    emailToExternalUsers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      portalUrl: "www.ezofis.com/portal/amazon/3pWg9ovmk2-TKzm8brzSA",
    };
  },

  methods: {
    updateInternalUsers(internalUsers) {
      this.$emit("update:emailToInternalUsers", internalUsers);
    },

    updateExternalUsers(externalUsers) {
      this.$emit("update:emailToExternalUsers", externalUsers);
    },
  },
};
</script>

<style lang="scss" scoped></style>
