<template>
  <div id="portal-publish">
    <!-- action bar -->
    <template v-if="checkFeature">
      <ActionBar v-model="tab" :tabs="tabs" @save="saveSettings" />
    </template>

    <template v-else>
      <ActionBar v-model="tab" :tabs="filterTabs" @save="saveSettings" />
    </template>

    <!-- ... -->

    <!-- settings -->

    <BaseScrollbar height="calc(100vh - 138px)">
      <div class="row q-pb-xl">
        <ValidationObserver ref="portal">
          <!-- publish options -->

          <PublishOptions
            ref="PUBLISH"
            :mode="mode"
            :publish-option.sync="publishOption"
            :publish-schedule.sync="publishSchedule"
            :unpublish-schedule.sync="unpublishSchedule"
            :is-active="tab === 'PUBLISH'"
            @click="tab = 'PUBLISH'"
          />

          <!-- ... -->

          <BaseSeparator v-if="checkFeature" has-left-inset />

          <!-- share options -->

          <ShareOptions
            v-if="checkFeature"
            ref="SHARE"
            :mode="mode"
            :is-active="tab === 'SHARE'"
            :email-to-internal-users.sync="emailToInternalUsers"
            :email-to-external-users.sync="emailToExternalUsers"
            @click="tab = 'SHARE'"
          />

          <!-- ... -->

          <BaseSeparator v-if="checkFeature" has-left-inset />

          <!-- embed options -->

          <EmbedOptions
            v-if="checkFeature"
            ref="EMBED"
            :mode="mode"
            :is-active="tab === 'EMBED'"
            @click="tab = 'EMBED'"
          />

          <!-- ... -->
        </ValidationObserver>

        <!-- ... -->
      </div>
    </BaseScrollbar>

    <!-- ... -->
  </div>
</template>

<script>
import ActionBar from "../components/ActionBar.vue";
import { ValidationObserver } from "vee-validate";
import { cloneDeep } from "lodash-es";
import PublishOptions from "./components/publish-options/PublishOptions.vue";
import ShareOptions from "./components/share-options/ShareOptions.vue";
import EmbedOptions from "./components/embed-options/EmbedOptions.vue";

export default {
  name: "PortalPublish",

  components: {
    ActionBar,
    ValidationObserver,
    PublishOptions,
    ShareOptions,
    EmbedOptions,
  },

  props: {
    portalSettings: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      mode: "NEW",
      tab: "PUBLISH",
      tabs: [
        {
          id: this.$nano.id(),
          label: "publish",
          value: "PUBLISH",
          isShow: true,
        },
        {
          id: this.$nano.id(),
          label: "share",
          value: "SHARE",
          isShow: false,
        },
        {
          id: this.$nano.id(),
          label: "embed",
          value: "EMBED",
          isShow: false,
        },
      ],

      // publish

      publishOption: "",
      publishSchedule: "",
      unpublishSchedule: "",

      // share options

      emailToInternalUsers: [],
      emailToExternalUsers: [],
    };
  },

  computed: {
    checkFeature() {
      let origin = location.origin;
      if (
        origin === "http://localhost:8080" ||
        origin === "https://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        return true;
      }
      return false;
    },

    filterTabs() {
      return this.tabs.filter((tab) => tab.isShow);
    },
  },

  watch: {
    tab() {
      this.$refs[this.tab].$el.scrollIntoView({ behavior: "smooth" });
    },

    portalSettings: {
      immediate: true,
      deep: true,
      handler() {
        const portalSettings = cloneDeep(this.portalSettings);

        this.publishOption = portalSettings.publish.publishOption;
        this.publishSchedule = portalSettings.publish.publishSchedule;
        this.unpublishSchedule = portalSettings.publish.unpublishSchedule;
      },
    },
  },

  methods: {
    saveSettings() {
      const portalSettings = cloneDeep(this.portalSettings);

      portalSettings.publish.publishOption = this.publishOption;
      portalSettings.publish.publishSchedule = this.publishSchedule;
      portalSettings.publish.unpublishSchedule = this.unpublishSchedule;

      this.$emit("update", portalSettings);
    },
  },
};
</script>

<style lang="scss" scoped></style>
