<template>
  <FormSection icon="eva-code-download" :is-active="isActive" v-on="$listeners">
    <template #title>Embed</template>
    <template #description>
      Easily embed this portal in your website or in your web app through
      multiple ways, including iFrame, JavaScript, and as a button.
    </template>

    <BaseFeatureLock>
      <FormFieldLabel label="Embed As" />

      <div class="options-wrapper">
        <CodeArea
          v-for="option in embedOptions"
          :key="option.id"
          :value="embedOption === option.label"
          :label="option.label"
          :description="option.description"
          :code="option.code"
          :class="{ 'q-my-md': option.label === 'JavaScript' }"
          @click="embedOption = option.label"
        />
      </div>
    </BaseFeatureLock>
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import CodeArea from "./components/CodeArea.vue";

export default {
  name: "EmbedOptions",

  components: {
    FormSection,
    FormFieldLabel,
    CodeArea,
  },

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },
  },

  data() {
    return {
      embedOption: "",
      embedOptions: [
        {
          id: this.$nano.id(),
          label: "iFrame",
          description:
            "Use this code to embed the portal as an iFrame on a web page",
          code: '<iframe id="3pWg9ovmk2-TKzm8brzSA" title="Leave Request" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allowfullscreen="true" src="www.ezofis.com/portal/amazon/3pWg9ovmk2-TKzm8brzSA" frameborder="0" style="min-width:840px;height:auto;border:none"></iframe>',
        },
        {
          id: this.$nano.id(),
          label: "JavaScript",
          description:
            "Use this code to embed the portal as a page element and not as an external resource",
          code: '<script type="text/javascript" src="www.ezofis.com/portal/amazon/3pWg9ovmk2TKzm8brzSA"/>',
        },
        {
          id: this.$nano.id(),
          label: "Button",
          description:
            "Use this code to open the portal as a popup on a button click",
          code: '<script src="www.ezofis.com/portal/amazon/3pWg9ovmk2-TKzm8brzSA" type="text/javascript">portalsAppFeedbackButton({ portalId: "6177b2e16b145458aa39781a", buttonText: "Feedback", buttonTextColor: "#FFFFFF", buttonBackground: "#11c4e0", verticalAlligment: "middle", horizontalAlligment: "right", width: 500,height: 400}); />',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.options-wrapper {
  border-top: 1px solid var(--divider-color);
  padding-top: 16px;
}
</style>
