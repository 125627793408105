<template>
  <div id="portal-header">
    <!-- portal info -->

    <div class="row items-center">
      <!-- settings -->

      <BaseActionButton
        v-tooltip.top="'edit portal settings'"
        icon="eva-edit"
        is-flat
        color="primary"
        class="q-mr-md"
        @click="$emit('settings')"
      />

      <!-- ... -->

      <div>
        <div class="title-2">{{ portalName }}</div>
        <div class="description">{{ portalDescription }}</div>
      </div>
    </div>

    <!-- ... -->

    <q-space />

    <BaseButton label="save" icon="eva-save-outline" @click="$emit('save')" />
  </div>
</template>

<script>
export default {
  name: "PortalHeader",

  props: {
    portalSettings: {
      type: Object,
      required: true,
    },
  },

  computed: {
    portalName() {
      return this.portalSettings.general.name || "Portal name";
    },

    portalDescription() {
      return this.portalSettings.general.description || "portal description";
    },
  },
};
</script>

<style lang="scss" scoped>
#portal-header {
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  background-color: var(--component-bg-color);

  .description {
    @extend .text-sm;
    color: var(--icon-color);
  }
}
</style>
