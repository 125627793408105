<template>
  <div id="email-to">
    <FormFieldLabel label="Email To" />

    <div class="options-wrapper">
      <!-- internal users -->

      <CheckboxField
        v-model="showInternalUsers"
        label="Internal Users"
        description="Share the portal with users within your organization"
        class="q-mb-md"
      />

      <MultiSelectField
        v-if="showInternalUsers"
        :value="emailToInternalUsers"
        :options="internalUsers"
        class="q-mb-md"
        @input="updateInternalUsers"
      />

      <!-- ... -->

      <!-- external users -->

      <CheckboxField
        v-model="showExternalUsers"
        label="External Users"
        description="Share the portal with users outside your organization"
      />

      <TextAreaField
        v-if="showExternalUsers"
        :value="externalUsers"
        placeholder="Add each email on a new line"
        class="q-mt-md"
        @input="updateExternalUsers"
      />

      <!-- ... -->
    </div>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";

export default {
  name: "EmailTo",

  components: {
    FormFieldLabel,
    CheckboxField,
    MultiSelectField,
    TextAreaField,
  },

  props: {
    emailToInternalUsers: {
      type: Array,
      required: true,
    },

    emailToExternalUsers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      showInternalUsers: false,
      internalUsers: [],
      showExternalUsers: false,
    };
  },

  computed: {
    externalUsers() {
      return this.emailToExternalUsers.join("\n");
    },
  },

  methods: {
    updateInternalUsers(internalUsers) {
      this.$emit("updateInternalUsers", internalUsers);
    },

    updateExternalUsers(value) {
      const externalUsers = value.split("\n");
      this.$emit("updateExternalUsers", externalUsers);
    },
  },
};
</script>

<style lang="scss" scoped>
#email-to {
  .options-wrapper {
    border-top: 1px solid var(--divider-color);
    padding-top: 16px;
  }
}
</style>
